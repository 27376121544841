import Vue from 'vue'
import WebSocketWrapper from 'ws-wrapper'
import EventBus from '@/plugins/eventbus'

// TODO maybe get rid of queue system, it kinda makes edge cases happen

// Exponential back-off configuration for auto-reconnect
const RECONNECT_VALUE_MIN = 1e3 // 1 second
const RECONNECT_VALUE_MAX = 1e3 * 60 // 1 minute
const RECONNECT_VALUE_FACTOR = 1.2
let reconnectValue = RECONNECT_VALUE_MIN

const URL = `${CONFIG.ws.secure ? 'wss' : 'ws'}://${CONFIG.ws.url}`
const socket = new WebSocketWrapper(
  new WebSocket(URL),
  {
    debug: false,
    requestTimeout: 60 * 1e3, // 60 seconds
  },
)

socket.autoReconnect = true

// util
socket.reconnect = () => {
  socket.autoReconnect = false
  socket.disconnect()
  socket.connect(URL)
  reconnectValue = RECONNECT_VALUE_MIN
  socket.autoReconnect = true
}

socket.connect = url => {
  socket.bind(new WebSocket(url))
}

// auto reconnect logic
socket.on('disconnect', async function onDisconnect(event, wasOpen) {
  if (wasOpen) {
    reconnectValue = RECONNECT_VALUE_MIN
  } else {
    reconnectValue = Math.min(reconnectValue * RECONNECT_VALUE_FACTOR, RECONNECT_VALUE_MAX)
  }

  if (this.autoReconnect) {
    setTimeout(() => {
      socket.connect(URL)
    }, Math.random() * reconnectValue)
  }
})

Vue.prototype.$socket = socket

export default socket

EventBus.$once('app.created', async vm => {
  // do something
})
