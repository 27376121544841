import { stateSetters, stateGetters } from '@/store/store-utils'
import { CONGA } from '@/utils/constants/socket-events'

const defaultState = {
  conga: null,
}

export default {
  name: 'conga',
  namespaced: true,
  state: () => defaultState,
  getters: {
    ...stateGetters(defaultState),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    [`SOCKET_${CONGA}`]({ commit }, conga) {
      commit('setConga', conga)
    },
  },
}
