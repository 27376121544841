/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */

/**
 * FFMPEG COMMANDS FOR BATCH CONVERSION
 *
 * WAV -> MP3: FOR /F "tokens=*" %G IN ('dir /b *.wav') DO ffmpeg -i "%G" -acodec mp3 "%~nG.mp3"
 * WAV -> WEBM: FOR /F "tokens=*" %G IN ('dir /b *.wav') DO ffmpeg -i "%G" "%~nG.webm"
 */

import { Howl, Howler } from 'howler'

// mute on page load
Howler.mute(true)
Howler.autoUnlock = true

let soundLocked = true

export function registerSound(path, opts = {}) {
  if (!path) throw new Error('No sound path provided')
  if (Howler.noAudio) throw new Error('Browser does not support audio')

  const howl = new Howl({
    src: [
      require(`@/assets/sounds/${path}.webm`),
      require(`@/assets/sounds/${path}.mp3`),
    ],
    format: ['webm', 'mp3'],
    autoplay: false,
    preload: true,
    onplay() {
      if (soundLocked) {
        console.log('sound unlocked, unmuting')
        soundLocked = false
        Howler.mute(false)
      }
    },
    ...opts,
  })

  return howl
}

export function setVolume(vol) {
  Howler.volume(vol)
}

export const getVolume = () => Howler.volume()

export function setMute(state) {
  Howler.mute(state)
}
