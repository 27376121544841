import insane from 'insane'

export const DEFAULT_SETTINGS = {
  interface: {
    chat: {
      visible: true,
    },
  },
  sounds: {
    volumes: {
      master: 0.7,
      game: 0.5,
      items: 0.8,
      notifications: 0.6,
    },
  },
  games: {
    cases: {
      sounds: true,
      turbo: false,
    },
    caseBattles: {
      sounds: true,
    },
  },
  onboarding: {
    disabled: false,
    seen: [],
  },
}

export const BAN_FLAGS = {
  chat: 2 ** 0,
  full: 2 ** 1,
  bet: 2 ** 2,
}

export const CRYPTO_CODES = {
  bitcoin: 'btc',
  ethereum: 'eth',
  usdt: 'usdt',
  litecoin: 'ltc',
}

export const CRYPTO_NAMES = {
  bitcoin: 'Bitcoin',
  ethereum: 'Ethereum',
  usdt: 'Tether',
  litecoin: 'Litecoin',
}

export const CRYPTO_POW = {
  chainlink: 18,
  ethereum: 18,
  bitcoin: 8,
  litecoin: 8,
  serum: 6,
  usdt: 6,
}

export const BET_STATUS = {
  pending: 0,
  completed: 1,
  refunded: 2,
  errored: 3,
}

export const TX_STATES = {
  complete: 1,
  success: 2,
  pending: 3,
  cancelled: 4,
  failure: 5,
  manual: 6,
}

export const INSANE_CONFIG = {
  allowedClasses: { a: ['link'] },
  allowedAttributes: {
    ...insane.defaults.allowedAttributes,
    a: ['class', 'href', 'name', 'target', 'rel'],
  },
}

export const LEVELS = [0, 1400, 3300, 6000, 9600, 14100, 19500, 25800, 33100, 41400, 50700, 51900, 53800, 56500, 60100, 64600, 70000, 76300, 83600, 91900, 101200, 104500, 109900, 117600, 127700, 140300, 155400, 173200, 193700, 217000, 243100, 246900, 253100, 261900, 273400, 287800, 305100, 325400, 348800, 375400, 405200, 412900, 425400, 443100, 466200, 495000, 529700, 570400, 617300, 670500, 730200, 741700, 760500, 787100, 821800, 865000, 917000, 978100, 1048500, 1128400, 1218000, 1232400, 1255900, 1289100, 1332500, 1386600, 1451700, 1528100, 1616100, 1716000, 1828000, 1856900, 1904000, 1970500, 2057400, 2165600, 2295800, 2448600, 2624600, 2824400, 3048400, 3072500, 3111700, 3167100, 3239500, 3329700, 3438200, 3565500, 3712200, 3878700, 4065300, 4210100, 4445600, 4778300, 5213100, 5754300, 6405400, 7169700, 8050100, 9049100, 10169200, 10217400, 10295900, 10406800, 10551700, 10732100, 10949100, 11203800, 11497200, 11830200, 12203500, 12251700, 12330200, 12441100, 12586000, 12766400, 12983400, 13238100, 13531500, 13864500, 14237800, 14286000, 14364500, 14475400, 14620300, 14800700, 15017700, 15272400, 15565800, 15898800, 16272100, 16320300, 16398800, 16509700, 16654600, 16835000, 17052000, 17306700, 17600100, 17933100, 18306400, 18354600, 18433100, 18544000, 18688900, 18869300, 19086300, 19341000, 19634400, 19967400, 20340700, 20533700, 20847800, 21291400, 21871200, 22592800, 23461000, 24480100, 25653900, 26985900, 28479300, 28768900, 29240000, 29905400, 30775100, 31857500, 33159800, 34688500, 36449300, 38447400, 40687600, 42135800, 44491600, 47818600, 52167200, 57579400, 64091300, 71734900, 80538900, 90529500, 101730600, 102937400, 104900500, 107673000, 111296800, 115806900, 121233500, 127603200, 134939900, 143265400, 152599700, 153806500, 155769600, 158542100, 162165900, 166676000, 172102600, 178472300, 185809000, 194134500, 203468800]

export const MAX_SHOP_SELECT = 100
