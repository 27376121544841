import Vue from 'vue'
import Toast from '@/components/Toast'
import EventBus from '@/plugins/eventbus'
import { registerSound } from '@/modules/sound'

// TODO Backport to BOILERPLATE
const SOUNDS = {
  success: registerSound('toast-success'),
  error: registerSound('toast-error'),
  info: registerSound('toast-info'),
  warning: registerSound('toast-warning'),
}

let parentVm = null
let currentToast = null

function spawn(propsData) {
  if (!parentVm) throw new Error('No parent vm found')

  const ToastComponent = Vue.extend(Toast)
  return new ToastComponent({
    el: document.createElement('div'),
    parent: parentVm,
    propsData,
    onClose() {
      // FIXME: this is broken, only works every other time
      currentToast = null
    },
  })
}

export function open(message, opts = {}) {
  if (!message) throw new Error('No message provided')

  const propsData = {
    message,
    title: opts.title || null,
    options: {
      type: opts.type || 'info',
      closable: true,
      multiLine: !!opts.title || message.length > 80,
      sound: true,
      ...opts,
    },
  }

  if (propsData.options.sound === true) {
    const sound = SOUNDS[propsData.options.type]

    if (sound) propsData.options.sound = sound
  }

  if (currentToast) currentToast.close()
  currentToast = spawn(propsData)
}

export function close() {
  if (currentToast) currentToast.close()
}

EventBus.$once('app.created', vm => {
  parentVm = vm
})

Vue.prototype.$toast = {
  open,
  error: (m, o) => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(m)
    }

    let message = m

    if (m instanceof Error) {
      message = m.message
    }

    return open(message, { ...o, type: 'error' })
  },
  warning: (m, o) => open(m, { ...o, type: 'warning' }),
  success: (m, o) => open(m, { ...o, type: 'success' }),
  info: (m, o) => open(m, { ...o, type: 'info' }),
  assign(instance) {
    parentVm = instance
  },
}

export default true
