<template>
  <v-snackbar
    v-model="open"
    app bottom right
    color="grey600"
    v-bind="options"
    content-class="d-flex align-center"
    transition="scroll-x-transition"
  >
    <div
      v-if="hasAction"
      class="click-overlay" @click="onAction(options.options.action)"
    />

    <v-icon
      v-if="icon" class="lh-1 mr-3 text-h6"
      :class="`${options.type}--text`"
      v-text="icon"
    />

    <div class="flex-grow-1">
      <div v-if="title" class="text-subtitle-1 font-weight-bold" v-text="title" />

      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="text-body-2 toast-text" v-html="message" />
    </div>
    <v-btn
      v-if="options.closable"
      small text icon :ripple="false"
      class="ml-3 dismiss-btn"
      @click.stop="dismiss"
    >
      <v-icon class="text-body-1">
        mdi-close
      </v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
import { sleep, removeElement } from '@/utils'

const TYPE_ICONS = {
  success: 'mdi-check-bold',
  info: 'mdi-information',
  warning: 'mdi-alert',
  error: 'mdi-close-octagon',
}

export default {
  name: 'Toast',
  props: {
    title: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    hasAction() {
      return !!this.options.options?.action
    },
    icon() {
      if (!this.options.type) return null
      return TYPE_ICONS[this.options.type]
    },
  },
  watch: {
    open(val) {
      this.onToastStateChange(val)
    },
  },
  beforeMount() {
    document.getElementById('app').appendChild(this.$el)
  },
  mounted() {
    this.open = true // trigger appear animation

    // play toast sound
    if (this.options.sound) {
      // wait for animation
      setTimeout(() => {
        const { volume } = this.options

        const sound = this.options.sound.play()
        if (volume) this.options.sound.volume(volume, sound)
      }, 100)
    }
  },
  methods: {
    onAction(action) {
      console.log(action)
      if (!action) return
      NotifActionHandler[action.type]?.(action, this)

      if (this.options.closable) {
        this.dismiss()
      }
    },
    async onToastStateChange(open) {
      if (!open) {
        if (this.$options.onClose) this.$options.onClose()

        await sleep(300) // wait for animation

        this.$destroy()
        removeElement(this.$el)
      }
    },
    dismiss() {
      this.close()
    },
    close() {
      this.open = false
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-snack__action {
  display: none;
}

.click-overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.dismiss-btn {
  z-index: 3;
}

.toast-text ::v-deep p {
  margin-bottom: 0;
}
</style>
