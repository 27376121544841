import { stateGetters, stateSetters } from '@/store/store-utils'

import mutations from './mutations'
import actions from './actions'
import socketActions from './socket-actions'

const defaultState = {
  session: undefined,
}

export default {
  name: 'auth',
  namespaced: true,
  state: () => defaultState,
  getters: {
    ...stateGetters(defaultState),
  },
  mutations: {
    ...stateSetters(defaultState),
    ...mutations,
  },
  actions: {
    ...actions,
    ...socketActions,
  },
}
