export default {
  setUserBalance(state, val) {
    if (!state.session) return
    state.session.balance = val
  },
  setUserProp(state, [prop, val]) {
    if (!state.session || !prop) return
    state.session[prop] = val
  },
  incUserProp(state, [prop, val]) {
    if (!state.session || !prop) return
    state.session[prop] += val
  },
  addOrRemoveBalance(state, val) {
    if (!state.session) return
    state.session.balance += val
  },
}
