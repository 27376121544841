<template>
  <v-container class="py-12 px-2 px-sm-8">
    <v-card color="grey400">
      hi
    </v-card>
  </v-container>
</template>

<script>
export default {
  metaInfo: {
    title: 'fuck you',
  },
}
</script>
