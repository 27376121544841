import Vue from 'vue'
import Modal from '@/components/Modal'
import EventBus from '@/plugins/eventbus'

// get all modals
const files = require.context('@/modals', true, /^(?!.*(views|components)).*\.vue$/i, 'lazy')

const modals = files.keys().reduce((acc, fileName) => {
  const componentName = fileName.split('/').pop().replace(/\.\w+$/, '')
  acc[componentName] = fileName.substr(2)
  return acc
}, {})

export const openModals = []
let parentVm = null

function spawn(propsData, id) {
  if (!parentVm) throw new Error('No parent parentVm found')

  const ModalComponent = Vue.extend(Modal)
  return new ModalComponent({
    el: document.createElement('div'),
    propsData,
    parent: parentVm,
    onClose() {
      openModals.splice(openModals.findIndex(i => i.id === id), 1)
    },
  })
}

export function open(type, options = {}, payload = {}) {
  if (!type) throw new Error('No modal specified')

  const id = Math.random().toString(36).substr(2, 9)
  const modal = modals[type]

  const comp = spawn({ options, payload, modal }, id)
  openModals.push({ id, type })

  return comp
}

EventBus.$once('app.created', vm => {
  parentVm = vm
})

Vue.prototype.$modal = {
  open,
  assign(vm) {
    parentVm = vm
  },
}

export default true
