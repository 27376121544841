<template>
  <span v-text="formatted" />
</template>

<script>
function formatSeconds(secs) {
  function pad(n) {
    return (n < 10 ? `0${ n}` : n)
  }

  const h = Math.floor(secs / 3600)
  const m = Math.floor(secs / 60) - (h * 60)
  const s = Math.floor(secs - h * 3600 - m * 60)

  if (h > 0) {
    return `${pad(h)}:${pad(m)}:${pad(s)}`
  }

  return `${pad(m)}:${pad(s)}`
}

export default {
  props: {
    // TODO refactor to "to" and "from" system cuz this is holy yikes
    duration: {
      type: Number,
      required: true,
    },
    seconds: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: 0,
      interval: null,
    }
  },
  computed: {
    formatted() {
      if (this.seconds) return Math.floor(this.value)
      return formatSeconds(this.value)
    },
  },
  watch: {
    duration: {
      handler(val) {
        this.initTimer(val)
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    initTimer(val) {
      if (!val) return

      if (this.interval) {
        clearInterval(this.interval)
      }

      this.value = val
      if (this.value <= 0) return

      this.interval = setInterval(() => {
        this.value -= 1
        this.$emit('tick', this.value, val)

        if (this.value <= 0) {
          clearInterval(this.interval)
          this.$emit('finish')
        }
      }, 1e3)
    },
  },
}
</script>
