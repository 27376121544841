export const CONNECTED = 'connected'
export const DISCONNECTED = 'disconnect'
export const ERROR = 'error'

export const CONGA = 'app.conga'
export const ONLINE_COUNT = 'app.online'
export const SHUTDOWN = 'app.shutdown'

export const AUTH_ERROR = 'auth.error'
export const USER_INFO = 'app.user'
export const USER_BANNED = 'user.banned'
export const USER_NOTIFICATION = 'user.notification'
export const USER_BALANCE = 'user.balance'
export const USER_BALANCE_DIFF = 'user.balance.diff'
export const USER_XP_DIFF = 'user.xp.diff'
export const USER_TX_UPDATE = 'user.tx.diff'
export const USER_TRADE_UPDATE = 'user.botaction.diff'
export const USER_PROP_UPDATE = 'user.setProp'

export const USER_INVENTORY_ADD = 'user.inventory.add'
export const USER_INVENTORY_REMOVE = 'user.inventory.remove'
export const USER_INVENTORY_REPLACE = 'user.inventory.replace'

export const GAMES_SUMMARIES = 'games.summaries'
export const GAMES_FEED = 'games.feed'
export const GAMES_FEED_NEW = 'games.feed.new'

export const GAME_WHEEL_ROUND = 'game.wheel.round'
export const GAME_WHEEL_BET = 'game.wheel.newBet'
export const GAME_WHEEL_DELETE_BET = 'game.wheel.deleteBet'
export const GAME_WHEEL_HISTORY = 'game.wheel.history'
export const GAME_WHEEL_ROLL = 'game.wheel.roll'
export const GAME_WHEEL_LOCKED = 'game.wheel.locked'

export const GAME_SPINNERS_ACTIVE = 'game.spinners.active'
export const GAME_SPINNERS_NEW = 'game.spinners.new'
export const GAME_SPINNERS_JOINED = 'game.spinners.joined'
export const GAME_SPINNERS_LOCK = 'game.spinners.lock'
export const GAME_SPINNERS_ROLL = 'game.spinners.roll'
export const GAME_SPINNERS_EXPIRED = 'game.spinners.expired'
export const GAME_SPINNERS_WON = 'game.spinners.won'

export const GAME_COINFLIP_ACTIVE = 'game.coinflip.active'
export const GAME_COINFLIP_NEW = 'game.coinflip.new'
export const GAME_COINFLIP_REOPEN = 'game.coinflip.reopen'
export const GAME_COINFLIP_JOINING = 'game.coinflip.joining'
export const GAME_COINFLIP_JOINED = 'game.coinflip.joined'
export const GAME_COINFLIP_LOCK = 'game.coinflip.lock'
export const GAME_COINFLIP_ROLL = 'game.coinflip.roll'
export const GAME_COINFLIP_EXPIRED = 'game.coinflip.expired'

export const GAME_CASE_BATTLES_LIST = 'game.caseBattles.active'
export const GAME_CASE_BATTLES_NEW = 'game.caseBattles.new'
export const GAME_CASE_BATTLES_PLAYER_JOINED = 'game.caseBattles.playerJoined'
export const GAME_CASE_BATTLES_LOCK = 'game.caseBattles.lock'
export const GAME_CASE_BATTLES_ROLL_ROUND = 'game.caseBattles.rollRound'
export const GAME_CASE_BATTLES_FINISHED = 'game.caseBattles.finished'
export const GAME_CASE_BATTLES_EXPIRED = 'game.caseBattles.expired'

export const CHAT_MESSAGE = 'chat.message'
export const CHAT_CLEAR = 'chat.clear'
export const CHAT_PURGE = 'chat.purge'
export const CHAT_DELETE = 'chat.delete'
export const CHAT_MUTED = 'chat.muted'
export const CHAT_UNMUTED = 'chat.unmuted'

export const CRYPTO_PRICES = 'crypto.prices'
