<template>
  <v-navigation-drawer
    color="grey600" floating permanent app
    :width="width"
  >
    <v-list shaped nav>
      <v-list-item :input-value="true" link>
        <v-list-item-avatar>
          <v-img :src="user.avatar" />
        </v-list-item-avatar>

        <v-list-item-title>
          <h4 class="font-weight-bold text-truncate" v-text="user.name" />
          <p class="text-caption mb-0 text--disabled text-capitalize" v-text="user.role" />
        </v-list-item-title>
        <v-list-item-icon class="ml-0 mr-2">
          <v-btn color="grey200" icon small @click="logout()">
            <v-icon>
              mdi-arrow-right-bold-circle
            </v-icon>
          </v-btn>
        </v-list-item-icon>
      </v-list-item>
    </v-list>

    <v-list
      dense nav
      class="full-width"
    >
      <component
        :is="parent.children ? 'v-list-group' : 'v-list-item'"
        v-for="(parent, i) in nav" :key="i"
        :ref="parent.route" :to="parent.route"
        eager
        color="primary"
        :prepend-icon="parent.children ? parent.icon : null"
        no-action
      >
        <!-- is group -->
        <template v-if="parent.children" #activator>
          <v-list-item-title v-text="parent.name" />
        </template>

        <!-- is single item -->
        <template v-else>
          <v-list-item-icon>
            <v-icon v-text="parent.icon" />
          </v-list-item-icon>
          <v-list-item-title v-text="parent.name" />
        </template>

        <!-- is group, first subgroup -->
        <template v-if="parent.children">
          <component
            :is="child.children ? 'v-list-group' : 'v-list-item'"
            v-for="(child, o) in parent.children" :key="o"
            :ref="child.route" :to="child.route" exact
            eager no-action
            sub-group
          >
            <!-- is sub-sub-group -->
            <template v-if="child.children" #activator>
              <v-list-item-title v-text="child.name" />
            </template>

            <!-- is single item -->
            <template v-else>
              <v-list-item-title v-text="child.name" />
              <v-list-item-icon v-if="child.icon">
                <v-icon class="text-body-1 ml-1" v-text="child.icon" />
              </v-list-item-icon>
            </template>

            <!-- second subgroup -->
            <template v-if="parent.children">
              <v-list-item
                v-for="(subchild, p) in child.children" :key="p"
                :ref="subchild.route" :to="subchild.route" exact
                eager
              >
                <v-list-item-title v-text="subchild.name" />
                <v-list-item-icon v-if="subchild.icon">
                  <v-icon class="text-body-1 ml-1" v-text="subchild.icon" />
                </v-list-item-icon>
              </v-list-item>
            </template>
          </component>
        </template>
      </component>

      <!-- just used for vuetify-loader to import  :) -->
      <v-list-group v-if="false" />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { sleep } from '@/utils'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
    }),
    nav() {
      // TODO add disabled check
      return [
        {
          name: 'Statistics',
          route: '/stats',
          icon: 'mdi-chart-bar',
        },
        {
          name: 'Configs',
          icon: 'mdi-cogs',
          children: [
            {
              name: 'Conga',
              route: '/configs/conga',
              icon: 'mdi-cog',
            },
            {
              name: 'Regional data',
              route: '/configs/regional',
              icon: 'mdi-earth',
            },
          ],
        },
        {
          name: 'Users',
          icon: 'mdi-account-group',
          children: [
            {
              name: 'Search users',
              route: '/users/search',
              icon: 'mdi-account-search',
            },
            {
              name: 'Roles',
              route: '/users/roles',
              icon: 'mdi-chevron-double-up',
            },
            {
              name: 'Flairs',
              route: '/users/flairs',
              icon: 'mdi-tag-multiple',
            },
          ],
        },
        {
          name: 'Items',
          icon: 'mdi-cart',
          children: [
            {
              name: 'Search items',
              route: '/items/search',
              icon: 'mdi-magnify',
            },
            {
              name: 'Categories & rarities',
              route: '/items/settings',
              icon: 'mdi-cog',
            },
          ],
        },
        {
          name: 'Boxes',
          icon: 'mdi-archive',
          children: [
            {
              name: 'Search boxes',
              route: '/cases/search',
              icon: 'mdi-magnify',
            },
            {
              name: 'Categories',
              route: '/cases/settings',
              icon: 'mdi-cog',
            },
          ],
        },
        {
          name: 'Affiliates',
          route: '/affiliates',
          icon: 'mdi-account-star',
        },
        {
          name: 'Promotions',
          icon: 'mdi-star',
          children: [
            {
              name: 'Promo codes',
              route: '/promotions/codes',
              icon: 'mdi-wallet-giftcard',
            },
          ],
        },
      ]
    },
    width() {
      const { width } = this.$vuetify.breakpoint
      return Math.max(250, width * 0.35)
    },
  },
  async mounted() {
    await this.$nextTick()
    await sleep(300)

    const { path } = this.$route
    if (!path) return

    const [item] = this.$refs[path] ?? []
    if (!item) return
    this.expandListParentGroups(item)
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    expandListParentGroups(comp) {
      const parent = comp.$parent
      const isParentGroup = parent._name === '<VListGroup>'

      if (isParentGroup) {
        parent.isActive = true
        this.expandListParentGroups(parent)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-navigation-drawer__content {
  width: 300px;
  align-self: flex-end;
}
</style>
