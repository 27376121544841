<template>
  <span>
    <span
      v-text="$helpers.getCurrencySymbol(currency)"
    />
    <span v-if="!value && hideZeroValue" class="ml-1 text--disabled">&mdash;</span>
    <span v-else>{{ value | toScrap }}</span>
  </span>
</template>

<script>
export default {
  props: {
    markets: {
      type: Array,
      default: () => [],
    },
    market: {
      type: [Object, String],
      default: null,
    },
    value: {
      type: Number,
      default: null,
    },
    hideZeroValue: Boolean,
  },
  computed: {
    currency() {
      const { market } = this

      if (typeof market === 'object') {
        return market.currency
      }

      return this.markets.find(i => i._id === market)?.currency
    },
  },
}
</script>
