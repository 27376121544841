import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import theme from '@/styles/export.scss'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: false,
      variations: false,
    },
    themes: {
      dark: theme,
    },
  },
})
