/* eslint-disable no-bitwise */
import Vue from 'vue'

export const ROLE_MODERATOR = 'mod'
export const ROLE_ADMIN = 'admin'

export const PERMISSIONS = {
  CAN_BYPASS_CHAT_COOLDOWN: 2 ** 0,
  CAN_PING_EVERYONE: 2 ** 1,

  IGNORE_FROM_STATS: 2 ** 4,
  IGNORE_FROM_LEADERBOARD: 2 ** 23,

  // bypasses
  CAN_BYPASS_BET_LIMIT: 2 ** 15,
  CAN_BYPASS_DEPOSIT_REQUIREMENT: 2 ** 18,
  CAN_BYPASS_OFFERWALL_LOCK: 2 ** 25,

  // bans
  BLOCK_WITHDRAW: 2 ** 5,
  BLOCK_PVP_GAMES: 2 ** 20,
  BLOCK_LEVEL_REWARDS: 2 ** 24,
  CANNOT_SELL: 2 ** 21,
  DONT_AFFECT_AFFILIATE: 2 ** 19,

  // Staff
  CAN_BYPASS_STAFF_MODE: 2 ** 6,

  // Admin panel
  CAN_VIEW_ADMIN_PANEL: 2 ** 7,
  CAN_VIEW_STATISTICS: 2 ** 8,
  CAN_MUTE_USERS: 2 ** 2,
  CAN_MODIFY_CHAT: 2 ** 3,
  CAN_MODIFY_CONGA: 2 ** 9,
  CAN_MODIFY_ROLES: 2 ** 10,
  CAN_MODIFY_USERS: 2 ** 11,
  CAN_MODIFY_USER_BALANCE: 2 ** 14,
  CAN_MODIFY_BOTS: 2 ** 12,
  CAN_MODIFY_CODES: 2 ** 16,
  CAN_MODIFY_FLAIRS: 2 ** 17,
  CAN_MODIFY_GAMES: 2 ** 22,
  CAN_SEND_EMAILS: 2 ** 26,

  // Crypto
  CAN_WITHDRAW_CRYPTO: 2 ** 13,
}

/**
 * Checks user's permissions against a specific permission
 * @param {Number} userPermissions user's permission flag
 * @param {String} permission permission string to check
 */
export const hasPermission = (userPermissions, permission) => !!(userPermissions & PERMISSIONS[permission])

export const getRolePermissions = (role, roles) => {
  if (!role || !roles) return 0

  let permissions = roles[role]?.permissions ?? 0
  let inherits = roles[role]?.inherits ?? null

  while (inherits) {
    permissions |= roles[inherits]?.permissions ?? 0

    // don't get fucked by cyclic inheritance
    if (roles[inherits]?.inherits !== inherits) {
      inherits = roles[inherits]?.inherits ?? null
    } else {
      inherits = null
    }
  }

  return permissions
}

export const getPermissionsList = flags => {
  const perms = []

  for (const [key, val] of Object.entries(PERMISSIONS)) {
    if (flags & val) {
      perms.push(key)
    }
  }

  return perms
}

Vue.prototype.$hasPermission = hasPermission
