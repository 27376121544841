import deepmerge from 'deepmerge'
import EventBus from '@/plugins/eventbus'
import { DEFAULT_SETTINGS } from '@/utils/constants'
import { setVolume } from '@/modules/sound'

const deepclone = require('rfdc')()

const defaultState = {
  settings: null,
}

export default {
  name: 'settings',
  namespaced: true,
  state: () => defaultState,
  getters: {
    settings: s => deepclone(s.settings),
  },
  mutations: {
    setSettings(state, val) {
      state.settings = deepclone(val)
    },
  },
}

EventBus.$once('app.created', vm => {
  const localSettings = vm.$ls.get('settings', null)
  const settings = deepmerge(DEFAULT_SETTINGS, localSettings || {}, {
    arrayMerge: (_, localSettings) => localSettings,
  })

  vm.$store.commit('settings/setSettings', settings)
  setVolume(settings.sounds.volumes.master)

  if (!localSettings) {
    vm.$ls.set('settings', settings)
  }

  // when the settings change
  vm.$store.watch((state, getters) => getters['settings/settings'], val => {
    setVolume(val.sounds.volumes.master)
    vm.$ls.set('settings', val)
  })
})
