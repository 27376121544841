<template>
  <VTextField
    ref="input"
    v-currency="{
      currency: null,
      autoDecimalMode: false,
      valueAsInteger: true,
      precision: 2,
      ...options
    }"
    :value="formattedValue"
    v-bind="$attrs"
    @change="onChange"
    @input="onInput"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
  >
    <template #prepend-inner>
      <slot name="prepend-inner" />
    </template>
    <template #append>
      <slot name="append" />
    </template>
    <template #append-outer>
      <slot name="append-outer" />
    </template>
  </VTextField>
</template>

<script>
export default {
  name: 'VCurrencyField',
  props: {
    value: {
      type: Number,
      default: null,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formattedValue: null,
    }
  },
  watch: {
    value(value) {
      this.setValue(value)
    },
  },
  mounted() {
    this.setValue(this.value)
  },
  methods: {
    setValue(value) {
      this.$ci.setValue(this.$refs.input, value)
    },
    onInput(value) {
      this.$emit('input', this.$ci.getValue(this.$refs.input))
      this.formattedValue = value
    },
    onChange(value) {
      this.$emit('change', this.$ci.getValue(this.$refs.input))
      this.formattedValue = value
    },
    blur() {
      this.$refs.input.blur()
    },
  },
}
</script>
