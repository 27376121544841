import Vue from 'vue'
import timeAgo from '@/modules/time-ago'
import tinyDateFormat from '@bitty/format-date'

// custom filters
Vue.filter('toLocaleInt', val => val.toLocaleString('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}))

Vue.filter('toLocale', (val, maxDigits = 2) => val.toLocaleString('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: maxDigits,
}))

Vue.filter('toCurrency', val => val.toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}))

Vue.filter('toScrap', val => ((val ?? 0) / 100).toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}))

Vue.filter(
  'truncate',
  (text, stop, clamp) => text.slice(0, stop) + (stop < text.length ? clamp || '...' : ''),
)

Vue.filter('relativeTime', (ts, style = 'default', opts = {}) => timeAgo.format(new Date(ts), style, opts))
Vue.filter('toDateFormat', (ts, format) => tinyDateFormat(new Date(ts), format))
