<template>
  <v-container fluid fill-height class="d-flex flex-column align-center justify-center">
    <h1 class="text-h2 mb-6 primary500--text font-weight-bold">
      Missing permission
    </h1>
    <p class="mb-2 grey200--text">
      You dont have access to this content.
    </p>
    <v-btn exact small text to="/">
      <v-icon small class="mr-1">
        mdi-arrow-left
      </v-icon>
      <span>Back home</span>
    </v-btn>
  </v-container>
</template>

<script>
// TODO add funny bg pic

export default {
  metaInfo: {
    title: 'Missing permission',
  },
}
</script>
