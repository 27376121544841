<template>
  <v-dialog
    v-model="open"
    attach
    content-class="rounded-lg"
    v-bind="dialogOptions"
    :class="{loading, ready: fullyReady}"
    transition="scroll-y-transition"
    overlay-opacity=".8"
    @input="onDialogStateChange"
  >
    <transition name="t-scale-in-out" @after-enter="afterContentAnimated">
      <component
        :is="modalComponent"
        v-show="!loading && contentReady"
        class="modal-content"
        :payload="payload"
        :options.sync="localOptions"
        :close="close"
        @return-value="onReturnValue"
      />
    </transition>

    <transition name="t-scale-in-out">
      <v-sheet
        v-show="loading"
        color="grey800"
        class="modal-loader py-16 align-center justify-center"
      >
        <v-progress-circular color="grey300" indeterminate />
      </v-sheet>
    </transition>
  </v-dialog>
</template>

<script>
import { removeElement, sleep } from '@/utils'

export default {
  name: 'ModalContainer',
  props: {
    modal: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    payload: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      open: true,
      loading: false,
      contentReady: false,
      fullyReady: false,
      modalComponent: null,
      promise: null,
      localOptions: this.options,
    }
  },
  computed: {
    dialogOptions() {
      // override width during loading
      if (this.loading) {
        return {
          ...this.localOptions,
          scrollable: false,
          maxWidth: 150,
        }
      }

      return {
        maxWidth: 600,
        ...this.localOptions,
      }
    },
  },
  watch: {
    open(val) {
      this.onDialogStateChange(val)
      if (this.promise) this.promiseResolve(null)
    },
    loading(val) {
      if (val) {
        this.fullyReady = false
      }
    },
  },
  beforeMount() {
    document.getElementById('app').appendChild(this.$el)

    if (!document.documentElement.classList.contains('has-modal')) {
      document.documentElement.classList.add('has-modal')
    }
  },
  created() {
    this.modalComponent = () => this.loadModalContent()

    if (this.options.hasReturnValue) {
      this.promise = new Promise(res => {
        this.promiseResolve = res
      })
    }
  },
  methods: {
    async loadModalContent() {
      setTimeout(() => {
        if (!this.contentReady) {
          this.loading = true
        }
      }, 100)

      try {
        const { default: component } = await import(
          /* webpackChunkName: "[request].modal" */
          `@/modals/${this.modal}`
        )

        this.contentReady = true

        return component
      } catch (error) {
        console.error(error)
        return null
      } finally {
        this.loading = false
      }
    },
    async onDialogStateChange(open) {
      if (!open) {
        if (this.$options.onClose) this.$options.onClose()

        await sleep(300) // wait for transition

        this.$destroy()
        removeElement(this.$el)

        await this.$nextTick()

        // remove has-modal class if no more modals present
        const hasChildren = document.querySelector('.v-dialog__container')
        if (!hasChildren) {
          document.documentElement.classList.remove('has-modal')
        }
      }
    },
    afterContentAnimated() {
      this.fullyReady = true
    },
    close() {
      this.open = false
    },
    onReturnValue(val) {
      if (!this.promise) return
      this.promiseResolve(val)
    },
  },
}

// auth check
// if (component.needsAuth) {
//   const user = store.getters['auth/user']

//   if (!user) {
//     store.dispatch('auth/login')
//     return
//   }
// }

</script>

<style lang="scss" scoped>
.modal-loader {
  display: flex;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transition-delay: 0.1s !important;
}

.t-scale-in-out {
  &-enter {
    opacity: 0;
    transform: scale(0.8);
  }

  &-leave-to {
    opacity: 0;
    transform: scale(1);
  }

  &-enter-to, {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-content {
  transition: opacity 0.3s ease, transform 0.3s ease;

  &::v-deep .t-scale-in-out {
    &-enter-actve {
      transition-delay: 1s;
    }

    &-leave-actve {
      display: none;
    }
  }
}

.v-dialog__container {
  &::v-deep .v-dialog {
    position: relative;
  }

  &:not(.ready) {
    overflow: hidden;

    &::v-deep .v-dialog {
      min-height: 150px;
      box-shadow: none;

      .modal-content {
        overflow: hidden;
        max-height: 700px; // TODO let modals specify an `approxHeight` field for smoother transitions
      }
    }
  }
}

</style>
