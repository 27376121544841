import Vue from 'vue'
import createActivityDetector from 'activity-detector'
import store from '@/store'

const activityDetector = createActivityDetector({
  timeToIdle: 60e3,
  inactivityEvents: [],
  ignoredEventsWhenIdle: [],
})

activityDetector.on('idle', () => {
  store.commit('setUserActive', false)
})

activityDetector.on('active', () => {
  store.commit('setUserActive', true)
})

Vue.prototype.$activityDetector = activityDetector

// window focus
document.addEventListener('visibilitychange', () => {
  store.commit('setWindowVisible', document.visibilityState === 'visible')
}, true)

export default true
