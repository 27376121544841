import User from '@/store/models/User'

const logout = async () => {
  const resp = await fetch(`${CONFIG.api.url}/auth/logout`, { credentials: 'include' })
  const json = await resp.json()

  if (!resp.ok) throw new Error(json.response.message)
  return json
}

export default {
  async getInfo({ commit, getters }) {
    // NOTE does not include balance
    const user = await this.$socket.request('user.get')
    const localUser = getters.session

    commit('setSession', new User({ ...localUser, ...user }))
  },
  async logout({ commit }) {
    await logout()

    commit('setSession', null)
    this._vm.$socket.reconnect()
  },
}
