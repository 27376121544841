<template>
  <div class="username text-truncate">
    <slot name="prefix" />

    <fai v-if="user.bot" :icon="['fad', 'robot']" class="mr-1 blue300--text" />

    <span v-if="flairs.length && !noFlairs" class="flairs">
      <UserFlair
        v-for="flair in flairs"
        :key="flair._id"
        :flair="flair"
        class="flair"
      />
    </span>

    <span class="text-truncate" :style="{color}" v-text="user.name" />
    <slot name="suffix" />
  </div>
</template>

<script>
import User from '@/store/models/User'
import { LEVELS } from '@/utils/constants'
import UserFlair from '../UserFlair'

export default {
  components: { UserFlair },
  props: {
    user: {
      type: [User, Object],
      required: true,
    },
    noFlairs: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    flairs() {
      return this.user.flairs?.filter(i => i.icon) ?? []
    },
    color() {
      return this.user.flairs?.[0]?.color ?? null
    },
    level() {
      const nextLevel = LEVELS.findIndex(lvl => lvl > this.user.xp ?? 0)

      // we return nextLevel because index starts at 0 and first level is 1
      return (nextLevel !== -1 ? nextLevel : LEVELS.length) - 1
    },
  },
}
</script>

<style lang="scss" scoped>
.username {
  max-width: 100%;
}

.flairs {
  ::v-deep .user-flair {
    margin-right: 2px;
    font-size: 1em;
  }
}

::v-deep .user-level {
  margin-right: 2px;
}
</style>
