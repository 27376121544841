import Vue from 'vue'
import { sync } from 'vuex-router-sync'
import plugins from '@/plugins'
import store from '@/store'

import App from '@/App.vue'
import '@/styles/styles.scss'

const { vuetify, i18n, router } = plugins

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV === 'development'
Vue.config.performance = process.env.NODE_ENV === 'development'

Vue.prototype.$helpers = {
  getCurrencySymbol: currency => (0)
    .toLocaleString(navigator.language, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim(),
}

// sync vue-router state to vuex
sync(store, router)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  created() {
    if (process.env.NODE_ENV !== 'production') {
      window.vm = this
    }

    this.$bus.$emit('app.created', this)
  },
  render: h => h(App),
}).$mount('#app')
