// @link https://github.com/vuejs/vuex/issues/1268
export const stateSetters = s => Object.keys(s).reduce((acc, key) => {
  const setKey = `set${key.charAt(0).toUpperCase()}${key.slice(1)}`

  acc[setKey] = (state, payload) => { state[key] = payload }
  return acc
}, {})

// @link https://github.com/vuejs/vuex/issues/1268
export const stateGetters = state => Object.keys(state).reduce((acc, key) => {
  acc[key] = s => s[key]
  return acc
}, {})

/**
 * Watches a getter and resolves with value upon first change
 * @param {Object} store
 * @return {Array} getter value
 */
export const awaitGetterUpdate = (store, getter) => new Promise(res => {
  const unwatch = store.watch((state, getters) => getters[getter], data => {
    res(data)
    unwatch()
  })
})
