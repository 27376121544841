export const sleep = ms => new Promise(res => setTimeout(res, ms))
export const chunk = (arr, n) => arr.slice(0, ((arr.length + n - 1) / n) | 0).map((c, i) => arr.slice(n * i, n * i + n))

/* eslint-disable */
export const shallowCompare = (obj1, obj2) =>
  Object.keys(obj1).length === Object.keys(obj2).length
  && Object.keys(obj1).every(key =>
    obj2.hasOwnProperty(key) && obj1[key] === obj2[key]
  )
/* eslint-enable */

export const invertObject = obj => {
  const ret = {}
  Object.keys(obj).forEach(key => {
    ret[obj[key]] = key
  })
  return ret
}

export const getRandomFloatInRange = (min, max) => (Math.random() * (min - max) + max)

export const getRandomIntInRange = (min, max) => Math.floor(
  Math.random() * (Math.floor(max) - Math.ceil(min) + 1),
) + Math.ceil(min)

export function removeElement(el) {
  if (typeof el.remove !== 'undefined') {
    el.remove()
  } else {
    el.parentNode.removeChild(el)
  }
}

export const get = url => fetch(url).then(resp => {
  if (!resp.ok) throw new Error(resp.json())
  return resp.json()
})

export function debounce(fn, delay) {
  let timerId
  return function abc(...args) {
    if (timerId) clearTimeout(timerId)

    timerId = setTimeout(() => {
      fn.call(this, ...args)
      timerId = null
    }, delay)
  }
}

export const throttle = (fn, delay) => {
  let lastCall = 0
  return function abc(...args) {
    const now = new Date().getTime()
    if (now - lastCall < delay) return
    lastCall = now
    // eslint-disable-next-line consistent-return
    return fn.call(this, ...args)
  }
}

export const throttleEnsureLast = (fn, wait) => {
  let previouslyRun
  let queuedToRun

  return function invokeFn(...args) {
    const now = Date.now()

    queuedToRun = clearTimeout(queuedToRun)

    if (!previouslyRun || (now - previouslyRun >= wait)) {
      fn.call(this, ...args)
      previouslyRun = now
    } else {
      queuedToRun = setTimeout(invokeFn.bind(this, ...args), wait - (now - previouslyRun))
    }
  }
}

/* eslint-disable */
export const CenteredPopup = (url, title, w, h) => {
  // Fixes dual-screen position Most browsers Firefox
  const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left
  const dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width

  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height

  const left = width / 2 - w / 2 + dualScreenLeft
  const top = height / 2 - h / 2 + dualScreenTop
  const newWindow = window.open(url, title, `scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}`)

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus()
  }

  return newWindow
}
/* eslint-enable */

export function pickItemsAtValue(items, target) {
  let bestList = []
  let bestListValue = 0
  const sortedItemsByPrice = items.filter(el => el.price <= target).sort((a, b) => b.price - a.price)
  const n = sortedItemsByPrice.length

  for (let i = 0; i < n; i++) {
    const currentList = []
    let currentListValue = 0
    let totalValue = 0

    // Now just go down the array and fill it up with whatever amount of the most expensive item fits
    for (let j = i; j < n; j++) {
      const curr = sortedItemsByPrice[j]

      totalValue += curr.amount * curr.price

      const maxAmount = Math.min(Math.floor((target - currentListValue) / curr.price), curr.amount ?? 1)
      // eslint-disable-next-line no-continue
      if (maxAmount <= 0) continue

      curr.amount = maxAmount

      currentList.push(curr)

      currentListValue += maxAmount * curr.price
    }

    // if the currentlist is optimal, just return it
    if (currentListValue === target) return currentList

    // if the current list is better than the best one, update the best one
    if (currentListValue > bestListValue) {
      bestList = currentList
      bestListValue = currentListValue
    }

    // if the best list is more valuable than the rest of the items the algo would look at, just return the best list

    if (totalValue < bestListValue) return bestList
  }

  return bestList
}

export function toFormData(obj) {
  return Object.keys(obj).reduce((formData, key) => {
    formData.append(key, obj[key])
    return formData
  }, new FormData())
}
