import Vue from 'vue'
import Cryptoicon from 'vue-cryptoicon'
import {
  Eth, Btc, Usd, Usdt, Ltc,
} from 'vue-cryptoicon/src/icons'

Cryptoicon.add([Eth, Btc, Usd, Usdt, Ltc])

Vue.use(Cryptoicon)

export default true
