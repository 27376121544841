import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import store from '@/store'

import NotFound from '@/views/404.vue'
import NoPerms from '@/views/NoPerms.vue'
import Home from '@/views/home.vue'

import { open as openModal } from './modal'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/stats',
    name: 'Statistics',
  },
  {
    path: '/configs/regional',
    name: 'Configs regional',
    component: () => import(/* webpackChunkName: "view-regional" */ '@/views/configs/regional'),
    meta: {
      permission: 'CAN_MODIFY_CONGA',
    },
  },
  {
    path: '/users/search',
    name: 'Users search',
    meta: {
      permission: 'CAN_MODIFY_USERS',
    },
    component: () => import(/* webpackChunkName: "view-users-search" */ '@/views/users/search'),
  },
  {
    path: '/users/search/:id',
    name: 'Users inspect',
    meta: {
      permission: 'CAN_MODIFY_USERS',
    },
    component: () => import(/* webpackChunkName: "view-users-inspect" */ '@/views/users/inspect'),
  },
  {
    path: '/users/flairs',
    name: 'User flairs',
    meta: {
      permission: 'CAN_MODIFY_FLAIRS',
    },
    component: () => import(/* webpackChunkName: "view-flairs" */ '@/views/users/flairs'),
  },
  {
    path: '/users/roles',
    name: 'User roles',
    meta: {
      permission: 'CAN_MODIFY_ROLES',
    },
    component: () => import(/* webpackChunkName: "view-roles" */ '@/views/users/roles'),
  },
  // items
  {
    path: '/items/search',
    name: 'Items',
    component: () => import(/* webpackChunkName: "view-items-search" */ '@/views/items/search'),
  },
  {
    path: '/items/create',
    name: 'Items create',
    component: () => import(/* webpackChunkName: "view-items-create" */ '@/views/items/create'),
  },
  {
    path: '/items/edit/:id',
    name: 'Items edit',
    component: () => import(/* webpackChunkName: "view-items-edit" */ '@/views/items/edit'),
  },
  {
    path: '/items/settings',
    name: 'Items settings',
    component: () => import(/* webpackChunkName: "view-items-settings" */ '@/views/items/settings'),
  },
  // cases
  {
    path: '/cases/search',
    name: 'Boxes',
    component: () => import(/* webpackChunkName: "view-cases-search" */ '@/views/cases/search'),
  },
  {
    path: '/cases/create',
    name: 'Boxes create',
    component: () => import(/* webpackChunkName: "view-cases-create" */ '@/views/cases/create'),
  },
  {
    path: '/cases/edit/:id',
    name: 'Boxes edit',
    component: () => import(/* webpackChunkName: "view-cases-edit" */ '@/views/cases/edit'),
  },
  {
    path: '/cases/settings',
    name: 'Boxes settings',
    component: () => import(/* webpackChunkName: "view-cases-settings" */ '@/views/cases/settings'),
  },
  {
    path: '/no-permission',
    name: 'no-permission',
    component: NoPerms,
  },
  {
    path: '*',
    name: '404',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
})

function waitForGetter(getter) {
  return new Promise(res => {
    const unsub = store.watch(getter, val => {
      res(val)
      unsub()
    })
  })
}

router.beforeEach(async (to, from, next) => {
  // avoid endless error loop
  if (to.name === '404') {
    next()
    return
  }

  // this route requires auth, check if logged in
  if (to.meta.permission) {
    let session = store.getters['auth/session']

    // if too early, wait for getter to be filled
    if (session === undefined) {
      session = await waitForGetter(state => state.auth.session)
    }

    // if not, redirect to login page.
    if (!session) {
      next({
        name: from?.name ?? 'Home',
        query: { authRedirect: to.fullPath },
      })

      openModal('auth', { maxWidth: 320, persistent: true })

      return
    }

    if (!session.hasPermission(to.meta.permission)) {
      next({ name: 'no-permission', replace: true })
    }
  }

  next()
})

export default router
