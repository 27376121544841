/* eslint-disable no-underscore-dangle */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import store from '@/store'
import socket from '@/plugins/socket'
import { DISCONNECTED, ERROR } from '@/utils/constants/socket-events'

function dispatchEvent(event, args = []) {
  const prefixedEvent = `SOCKET_${event}`

  for (const key in store._actions) {
    const action = key.split('/').pop()

    if (action === prefixedEvent) {
      // console.debug(`Broadcasting: ${event}, Data:`, ...args)
      store.dispatch(key, ...args)
    }
  }
}

socket.on('message', event => {
  const { data: json } = event
  const data = JSON.parse(json)

  if (!data.a) return

  const eventName = data.a.shift()
  const eventArgs = Object.values(data.a)

  dispatchEvent(eventName, eventArgs)
})

// special events
// socket.on('connect', event => {
//   // this event comes through message as well for some reason
//   // dispatchEvent(CONNECTED, [event])
// })

socket.on('disconnect', (event, wasOpen) => {
  dispatchEvent(DISCONNECTED, [{ event, wasOpen }])
})
socket.on('error', event => {
  dispatchEvent(ERROR, [event])
})

export default true
