<template>
  <v-avatar
    :size="size"
    :color="color"
    v-bind="$attrs"
  >
    <v-img v-if="user.avatar" :src="user.avatar" contain aspect-ratio="1" />
    <slot v-else name="empty">
      <span v-text="nameAbbr" />
    </slot>
  </v-avatar>
</template>

<script>
import User from '@/store/models/User'

export default {
  props: {
    user: {
      type: [User, Object],
      required: true,
    },
    color: {
      type: String,
      default: 'grey600',
    },
    size: {
      type: [Number, String],
      default: 40,
    },
  },
  computed: {
    nameAbbr() {
      return this.user?.name?.split(' ').map(i => i.substr(0, 1).toUpperCase()).join('') || 'A'
    },
  },
}
</script>
