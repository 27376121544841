<template>
  <v-tooltip top color="grey400">
    <template #activator="{on}">
      <span
        class="user-flair d-inline-block"
        :class="$vnode.data.staticClass"
        v-on="on"
      >
        <v-img
          v-if="flair.icon"
          :aspect-ratio="1"
          width="1em"
          contain
          :src="flair.icon"
        />
      </span>
    </template>
    <span v-text="flair.name" />
  </v-tooltip>
</template>

<script>
export default {
  props: {
    flair: {
      type: Object,
      required: true,
    },
  },
  computed: {
    type() {
      return this.flair.prefix.type
    },
    value() {
      return this.flair.prefix.value
    },
  },
}
</script>

<style lang="scss" scoped>
.v-image {
  display: inline-flex;
  font-size: 1.4em;
  vertical-align: middle;
  flex: 1 0 0;
}

::v-deep .user-flair-tooltip {
  background: red;
}
</style>
