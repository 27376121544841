import { Model } from '@vuex-orm/core'

const GAME_STATUS = {
  open: 0,
  locked: 1,
  rolling: 2,
  complete: 3,
  expired: 4,
}

const TEAM_COLORS = [
  '#cd412b',
  '#aade4e',
  '#3f99d7',
  '#f1c40f',
]

const COMMUNISM_COLOR = '#8e918e'

const MAX_COMPLETED_GAMES = 15

class CaseBattlesGame extends Model {
  static entity = 'case-battles-game'

  static fields() {
    return {
      id: this.string(''),
      status: this.number(0),
      round: this.number(0),
      creatorId: this.string(''),
      config: this.attr({}),
      players: this.attr({}),
      price: this.number(0),
      fairness: this.attr({}),
      data: this.attr({}),
      items: this.attr([]),
      // dates
      createdAt: this.attr(),
      expiresAt: this.attr(),
      lockingAt: this.attr(),
      finishedAt: this.attr(),
      lastRoundAt: this.attr(),
      //
      canShowWinners: this.attr(false),
      dontDelete: this.attr(false),
    }
  }

  get winners() {
    const teams = this.data?.winningTeams
    if (!teams) return []

    return Object.values(this.players).filter(player => teams.includes(player.team))
  }

  get totalWon() {
    return this.items.reduce((acc, i) => acc + i.price, 0)
  }

  get wonPerPlayer() {
    return Math.floor(this.totalWon / this.winners.length)
  }

  get teamItems() {
    const { players } = this

    return this.items.reduce((acc, item) => {
      const player = players[item.playerId]
      acc[player.team] = [...(acc[player.team] ?? []), item]
      return acc
    }, {})
  }

  get teamTotals() {
    return Object.entries(this.teamItems).reduce((acc, [teamId, items]) => {
      acc[teamId] = items.reduce((bcc, i) => bcc + i.price, 0)
      return acc
    }, {})
  }

  get creator() {
    return this.players[this.creatorId]
  }

  get private() {
    return this.config.private
  }

  get isCommunism() {
    return this.config.teams.length === 1
  }

  get isCursed() {
    return this.config.cursed
  }

  get isUneven() {
    if (this.isCommunism) return false

    const { teams } = this.config
    if (teams.length < 2) return false

    return teams.some(i => i.slots !== teams[0].slots)
  }

  get cases() {
    return this.config.cases
  }

  get rounds() {
    const { cases } = this
    return this.config.rounds.map(id => cases[id])
  }

  get teams() {
    const { isCommunism } = this
    return this.config.teams.map((team, i) => ({
      ...team,
      color: isCommunism ? COMMUNISM_COLOR : TEAM_COLORS[i],
    }))
  }

  get joinable() {
    if (this.status !== GAME_STATUS.open) return false

    return Object.keys(this.players).length < this.config.slots
  }

  get joinPrice() {
    const { funding } = this.config

    if (funding) {
      const creatorFunded = Math.ceil(this.price * (funding / 100))
      return this.price - creatorFunded
    }

    return this.price
  }

  get title() {
    if (this.isCommunism) {
      return 'Group Unbox'
    }

    let matchup = this.config.teams.map(i => i.slots).join('v')
    if (this.config.teams.length === 4) {
      matchup = '4-way'
    }

    if (this.isCursed) {
      return `Cursed ${matchup} Battle`
    }

    return `${matchup} Battle`
  }

  playersByTeam(id) {
    return Object.values(this.players).filter(i => i.team === id)
  }

  playerItems(steamid) {
    return this.items.filter(i => i.playerId === steamid)
  }

  banditsAvailable(config) {
    if (config.disabled) return false
    if (this.isCommunism && !config.allowCommunism) return false
    if (this.isCursed && !config.allowCursed) return false
    if (this.isUneven && !config.allowUnevenTeams) return false
    if (this.price > config.maxPrice) return false

    return true
  }

  // start to delete completed games after a while
  static afterCreate() {
    const completedGames = CaseBattlesGame.query()
      .where(game => game.status > GAME_STATUS.rolling && !game.dontDelete)
      .orderBy('finishedAt', 'asc')
      .get()

    if (completedGames.length > MAX_COMPLETED_GAMES) {
      const ids = completedGames.slice(0, completedGames.length - MAX_COMPLETED_GAMES).map(i => i.id)
      CaseBattlesGame.delete(game => ids.includes(game.id))
    }
  }
}

export default CaseBattlesGame
