import { Model } from '@vuex-orm/core'
import { v4 as uuid } from 'uuid'
import { hasPermission } from '@/utils/constants/permissions'
import { LEVELS } from '@/utils/constants'

class User extends Model {
  static entity = 'users'

  static fields() {
    return {
      id: this.uid(() => uuid()),
      steamid: this.string(),
      shortId: this.string(),
      name: this.string().nullable(),
      email: this.string().nullable(),
      emailVerified: this.boolean(),
      avatar: this.string().nullable(),
      balance: this.number(0),
      role: this.string().nullable(),
      tradeUrl: this.string().nullable(),
      permissions: this.number(0),
      xp: this.number(0),
      stats: this.attr({}),
      flairs: this.attr([]),
      connections: this.attr([]),
      betRequirement: this.number(0),
      faucetLastRedeemed: this.string().nullable(),
      affiliateOf: this.string().nullable(),
      referredBy: this.string().nullable(),
      claimedLevelRewards: this.attr([]),
    }
  }

  hasPermission(permission) {
    return hasPermission(this.permissions, permission)
  }

  get friendId() {
    return `${this.name}#${this.shortId}`
  }

  get betReq() {
    const req = this.betRequirement - this.stats.scrapBetSum

    return req > 0 ? req : 0
  }

  get hasRefCode() {
    return !!this.affiliateOf
  }

  get level() {
    const nextLevel = LEVELS.findIndex(lvl => lvl > this.xp)

    // we return nextLevel because index starts at 0 and first level is 1
    return (nextLevel !== -1 ? nextLevel : LEVELS.length) - 1
  }
}

export default User
