import {
  USER_INFO, AUTH_ERROR, USER_PROP_UPDATE,
  DISCONNECTED, CONNECTED,
} from '@/utils/constants/socket-events'
import User from '@/store/models/User'

export default {
  [`SOCKET_${AUTH_ERROR}`]({ dispatch }) {
    dispatch('logout')
  },
  [`SOCKET_${USER_INFO}`]({ commit }, data) {
    if (!data) {
      commit('setSession', null)
      return
    }

    commit('setSession', new User(data))
  },
  [`SOCKET_${USER_PROP_UPDATE}`]({ commit }, [field, val]) {
    commit('setUserProp', [field, val])
  },
  [`SOCKET_${DISCONNECTED}`]({ commit }, { event }) {
    // user was logged out elsewhere
    if (event.code === 4002) {
      commit('setSession', null)
    }
  },
  [`SOCKET_${CONNECTED}`]({ dispatch, getters }) {
    if (getters.session) {
      //
    }
  },
}
