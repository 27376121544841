<template>
  <div class="user-level d-inline-flex align-center">
    <v-tooltip bottom color="grey600">
      <template #activator="{on}">
        <v-responsive class="d-inline-flex" width="2em" :aspect-ratio="1" v-on="on">
          <PolygonSvg class="level-icon primary500--text" />
          <span class="level-label font-weight-semibold" v-text="level" />
        </v-responsive>
      </template>
      <span>Level {{ level }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import PolygonSvg from '@/assets/img/polygon.svg?inline'

const LEVELS_PER_TIER = 10
const MAX_TIER = 20

export default {
  components: {
    PolygonSvg,
  },
  props: {
    level: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    tier() {
      const tier = Math.min(Math.floor(this.level / LEVELS_PER_TIER), MAX_TIER)
      return tier
    },
  },
}
</script>

<style lang="scss" scoped>
.level-icon {
  width: 2em;
  height: 2em;
}

.user-level ::v-deep .v-responsive__content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.level-label {
  position: absolute;
  color: black;
  font-size: 0.9em;
  pointer-events: none;
}

::v-deep .user-flair-tooltip {
  background: red;
}
</style>
