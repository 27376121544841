<template>
  <v-app :style="styles">
    <template v-if="ready">
      <!-- <Navbar /> -->
      <Sidebar />

      <v-main>
        <transition name="scroll-y-reverse-transition" mode="out-in">
          <router-view class="flex-grow-1" />
        </transition>
        <Footer />
      </v-main>
    </template>
    <span v-else>Nothing to see here :)</span>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
// import Navbar from '@/components/layout/Navbar'
import Sidebar from '@/components/layout/Sidebar'

export default {
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s',
  },
  name: 'App',
  components: {
    // Navbar,
    Sidebar,
  },
  data: () => ({
    bottomNavOpen: true,
  }),
  computed: {
    ...mapGetters({
      user: 'auth/session',
      conga: 'conga/conga',
      settings: 'settings/settings',
      ui: 'ui',
      loaded: 'loaded',
    }),
    ready() {
      return this.loaded && !!this.conga && !!this.user
    },
    overlayActive() {
      return !this.ready
    },
    styles() {
      const { bottomNavOpen } = this
      const {
        top, left, right, bottom,
      } = this.$vuetify.application

      return {
        '--top': `${top}px`,
        '--right': `${right}px`,
        '--left': `${left}px`,
        '--bottom': bottomNavOpen ? `${bottom}px` : '0px',
      }
    },
  },
  created() {
    // close sidebar on mobile
    if (this.ui.sidebar.open && this.$vuetify.breakpoint.xs) {
      this.setSidebarOpen(false)
    }
  },
  methods: {
    ...mapMutations({
      setSettings: 'settings/setSettings',
      setSidebarOpen: 'setSidebarOpen',
      setChatOpen: 'setChatOpen',
    }),
    ...mapActions({
      logout: 'auth/logout',
    }),
    onSidebarToggle(isMobile) {
      if (isMobile && !this.ui.sidebar.open && this.ui.chat.open) {
        this.setChatOpen(false)
      }

      this.setSidebarOpen(!this.ui.sidebar.open)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-main ::v-deep .v-main__wrap {
  display: flex;
  flex-direction: column;
}
</style>
