import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import database from '@/store/database'
import socket from '@/plugins/socket'
import { stateGetters, stateSetters } from '@/store/store-utils'
import {
  CONNECTED, SHUTDOWN, DISCONNECTED, ERROR, ONLINE_COUNT,
} from '@/utils/constants/socket-events'

import modules from '@/store/modules'

Vue.use(Vuex)

const initialState = {
  online: 0,
  loaded: false,
  socket: {
    connected: false,
    reconnectionAttempts: 0,
  },
  ui: {
    sidebar: {
      open: true,
    },
  },
  windowVisible: true,
  userActive: true,
}

const store = new Vuex.Store({
  state: () => initialState,
  getters: {
    ...stateGetters(initialState),
    connected: s => s.socket.connected,
  },
  mutations: {
    ...stateSetters(initialState),
    setConnected: (state, v) => { state.socket.connected = v },
    setReconnectAttempt: (state, v) => {
      state.socket.reconnectionAttempts = v
    },
    incReconnectAttempt: state => {
      state.socket.reconnectionAttempts += 1
    },
    setSidebarOpen: (state, val) => {
      state.ui.sidebar.open = val
    },
  },
  actions: {
    [`SOCKET_${CONNECTED}`]({ commit, dispatch, getters }) {
      commit('setConnected', true)
      commit('setReconnectAttempt', 0)

      // first load
      if (!getters.loaded) {
        commit('setLoaded', true)
      }
    },
    [`SOCKET_${SHUTDOWN}`]({ commit, state }) {
      commit('setShuttingDown', true)
    },
    [`SOCKET_${DISCONNECTED}`]({ commit }, { event, wasOpen }) {
      commit('setConnected', false)
      commit('incReconnectAttempt')
    },
    [`SOCKET_${ERROR}`]({ commit }, error) {
      console.error(error)
    },
    [`SOCKET_${ONLINE_COUNT}`]({ commit }, count) {
      commit('setOnline', count)
    },
  },
  modules,
  plugins: [VuexORM.install(database)],
  strict: process.env.NODE_ENV !== 'production',
})

store.$socket = socket

export default store
